import React from "react";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import { Col, Container, Row } from "reactstrap";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Div } from "../elements";
import "./style.scss";
import {
  faLaptop,
  faUserFriends,
  faBlog
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html from "../../images/frontend-icons/html5.png";
import css3 from "../../images/frontend-icons/CSS3.png";
import javascript from "../../images/frontend-icons/javascript.png";
import sass from "../../images/frontend-icons/sass.png";
import bootstrap from "../../images/frontend-icons/bootstrap.png";
import figma from "../../images/frontend-icons/figma.png";
import git from "../../images/frontend-icons/git-icon.png";
import react from "../../images/frontend-icons/react.png";
import github from "../../images/frontend-icons/github.png";
import jquery from "../../images/frontend-icons/jquery.png";
import balsamiq from "../../images/frontend-icons/balsamiq.png";
import sap from "../../images/frontend-icons/SAP.png";
import ui5 from "../../images/frontend-icons/UI5B.png";

const laptop = <FontAwesomeIcon icon={faLaptop} />;
const consultant = <FontAwesomeIcon icon={faUserFriends} />;
const blog = <FontAwesomeIcon icon={faBlog} />;

const designerSkills = [
  {
    id: 0,
    title: "Designer",
    image: blog,
    titleText:
      "I value simple content structure, clean design patterns, and thoughtful interactions.",
    firstText: "Things I enjoy designing:",
    enjoy: ["UX", "UI", "Web", "Mobile", "Apps", "Logos"],
    toolsText: "Design Tools:",
    tools: ["Balsamiq Mockups", "Figma"],
    details: [
      { info: "Balsamiq", testImage: balsamiq },
      { info: "FIGMA", testImage: figma }
    ]
  }
];

const frontEndSkills = [
  {
    id: 1,
    title: "Front-end Developer",
    image: laptop,
    titleText:
      "I like to code things from scratch, and enjoy bringing ideas to life in the browser.",
    firstText: "Languages I speak:",
    enjoy: ["HTML", "Javascript", "React", "CSS", "SASS", "Less"],
    toolsText: "Design Tools:",
    tools: ["Bootstrap", "Codekit", "Codepen", "Github", "Gitlab"],
    details: [
      { info: "HTML5", testImage: html },
      { info: "CSS3", testImage: css3 },
      { info: "SAPUI5", testImage: ui5 },
      { info: "SASS", testImage: sass },
      { info: "BOOTSTRAP", testImage: bootstrap },
      { info: "JAVASCRIPT", testImage: javascript },
      { info: "Git", testImage: git },
      { info: "Github", testImage: github },
      { info: "REACT", testImage: react }
    ]
  }
];
const conultantSkills = [
  {
    id: 2,
    title: "Consultant",
    image: consultant,
    titleText:
      "I work myself into everything to find the best possible solution for your problem.",
    firstText: "Experiences I draw from:",
    enjoy: ["SAP", "Netweaver Portal", "ADOBE PRO", "Test"],
    toolsText: "Design Tools:",
    tools: ["SAP", "Netweaver Portal", "ADOBE PRO", "Test"],
    details: [{ info: "SAP", testImage: sap }]
  }
];

class SkillsComponent extends React.Component {
  render() {
    const { className, id } = this.props;
    return (
      <Div className={className} id={id}>
        <Container className="">
          <Zoom>
            <Row>
              {designerSkills.map((skill, i) => (
                <Col key={i} md={3}>
                  <h1 className="title is-size-4 is-spaced">{skill.title}</h1>
                  <div className="">
                    <ul className="ulSkillList">
                      {skill.details.map((sub, k) => (
                        <li className="skillList">
                          <img src={sub.testImage}></img>
                          <h3 className="heading-tertiary">{sub.info}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
              {frontEndSkills.map((skill, i) => (
                <Col key={i} md={6}>
                  <h1 className="title is-size-4 is-spaced">{skill.title}</h1>
                  <div className="">
                    <ul className="ulSkillList">
                      {skill.details.map((sub, k) => (
                        <li className="skillList">
                          <img src={sub.testImage}></img>
                          <h3 className="heading-tertiary">{sub.info}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
              {conultantSkills.map((skill, i) => (
                <Col key={i} md={3}>
                  <h1 className="title is-size-4 is-spaced">{skill.title}</h1>
                  <div className="">
                    <ul className="ulSkillList">
                      {skill.details.map((sub, k) => (
                        <li className="skillList">
                          <img src={sub.testImage}></img>
                          <h3 className="heading-tertiary">{sub.info}</h3>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Zoom>
        </Container>
      </Div>
    );
  }
}

export default SkillsComponent;
