import React from "react";
import "react-modal-video/scss/modal-video.scss";
import Typing from "react-typing-animation";
import { Col, Row } from "reactstrap";
import { Div, H2, H3 } from "../elements";
import "./style.scss";
import logo4 from "../../images/portfolio/landingpage/logo 4.png";

class LandingPage extends React.Component {
  render() {
    const { className, id } = this.props;
    return (
      <Div className={className} id={id}>
        <div className="header__logo-container">
          <a className="nuxt-link-exact-active nuxt-link-active">
            <img src={logo4}></img>
          </a>
        </div>
        <Row className="header__caption">
          <Col xl={{ size: 0, offset: 0 }} lg={6} md={6} xs={6}>
            <Div className="heroWrappper">
              <H3>SAP Portal Consultant & Developer</H3>
              <Typing>
                <H2>Hi, I'm Julian.</H2>
              </Typing>
              {/* <Ul>
                <Li className="loadmore">
                  <A href="#">Learn More</A>
                </Li>
              </Ul> */}
            </Div>
          </Col>
        </Row>
        <Row className="header__note">"
          <H3>Note: This page is in Beta and still under development!</H3>
        </Row>
      </Div>
    );
  }
}

export default LandingPage;
