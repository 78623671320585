import React from "react";
import { Div, H3, P, Span } from "../elements";
import "./style.scss";

class SectionTitle extends React.Component {
  render() {
    const { className, title, text, colorText } = this.props;
    return (
      <Div className={className}>
        <H3 className="h2">
          {title} {colorText && <Span className="colorText">{colorText}</Span>}{" "}
        </H3>
        <P>{text}</P>
      </Div>
    );
  }
}

export default SectionTitle;
