import React from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import { Col, Container, Row } from "reactstrap";
import { Div, P } from "../elements";
import SectionTitle from "../SectionTitle";
import "./style.scss";

const resumes = [
  {
    id: 0,
    title: "SAP NetweaverPortal Consultant || quindata GmbH",
    topics: [
      "SAP NetWeaver-Portal (Beratung, Planung, Betrieb, User- und Content-Administration)",
      "Realisierung von XML-Formularen ( GMC Inspire + ABAP )",
      "Entwicklung webbasierter ( SAPUI5 )- und Java Anwendungen",
      "Entwurf und Umsetzung von Userinterfaces und Styleguides"
    ],
    time: "2016 - heute"
  },
  {
    id: 2,
    title: "Trainee || Aurora DS",
    topics: ["JavaScript", "Selenium UI Testing", "Research"],
    time: "2015 - 2015"
  },
  {
    id: 1,
    title: "Ausbildung || quindata GmbH",
    topics: [
      "ABAP/Java Entwicklung",
      "SAP XML + Adobe Formulare",
      "SAP NetWeaver-Portal"
    ],
    time: "2013 - 2016"
  }
];

class About extends React.Component {
  render() {
    const { className, id } = this.props;
    return (
      <Div className={className} id={id}>
        <Container className="aboutContainer">
          <Row>
            <Col xl={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
              <Zoom>
                <SectionTitle
                  className="sectionTitle"
                  title="About Me"
                  Text="Front-end Developer. UI/UX Designer"
                />
              </Zoom>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <Zoom>
                <Div className="aboutContent">
                  <P>Hi,</P>
                  <P>
                    I'm a SAP Portal Consultant&Developer with over 3 years of
                    experience. Currently molding SAPUI5/Fiori applications at
                    quindata GmbH. My journey in the SAP industry startet in the
                    year 2013 when i started an apprenticeship as an application
                    developer. After my training, I was taken on in a full-time
                    position and have been responsible for the SAP NetWeaver
                    Portal Consulting and Development division since then.

                    In my spare time i like to work with other web technologies
                    like reactjs or webdesign in general. I love to create
                    frontends which people love to work with.
                  </P>


                </Div>
              </Zoom>
            </Col>
          </Row>
          <Row>
            <Col className="" lg={6} md={4} xs={12}>
              <Fade left>
                <Div className="aboutContent">
                  <h3
                    data-v-19becb7c=""
                    class="heading-tertiary u-margin-bottom-xl"
                  >
                    Personal Information
                  </h3>
                  <table data-v-19becb7c="">
                    <tbody data-v-19becb7c="" class="font-m table table__list">
                      <tr data-v-19becb7c="">
                        <th data-v-19becb7c="">Full Name</th>
                        <td data-v-19becb7c="">Julian Möller</td>
                      </tr>
                      <tr data-v-19becb7c="">
                        <th data-v-19becb7c="">Phone</th>
                        <td data-v-19becb7c="">
                          <a
                            data-v-19becb7c=""
                            href="tel:+49 17678942981"
                            class="u-text-underline"
                          >
                            +49 17678942981
                          </a>
                        </td>
                      </tr>
                      <tr data-v-19becb7c="">
                        <th data-v-19becb7c="">Email</th>
                        <td data-v-19becb7c="">
                          <a
                            data-v-19becb7c=""
                            href="mailto:julian.moeller@moeju.me"
                            class="u-text-underline"
                          >
                            julian.moeller@moeju.me
                          </a>
                        </td>
                      </tr>
                      <tr data-v-19becb7c="">
                        <th data-v-19becb7c="">Holiday</th>
                        <td data-v-19becb7c="">Sunday</td>
                      </tr>
                      <tr data-v-19becb7c="">
                        <th data-v-19becb7c="">Hobbies</th>
                        <td data-v-19becb7c="">Coding, Sports, Table-Tennis</td>
                      </tr>
                      <tr data-v-19becb7c="">
                        <th data-v-19becb7c="">More Information</th>
                        <td data-v-19becb7c="">
                          <a
                            download=""
                            target="_blank"
                            href="/files/Lebenlauf_Julian_Moeller_de.pdf"
                            class="btn btn--black scale-effect btn--animated u-text-uppercase"
                          >
                            Download CV
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Div>
              </Fade>
            </Col>
            <Col className="timeline" lg={6} md={4} xs={12}>
              {resumes.map((resume, i) => (
                <Fade right>
                  <ul key={i} className="resumeUL">
                    <div className="time">
                      <span>{resume.time}</span>
                    </div>
                    <p className="list-title has-text-primary has-text-weight-normal" />
                    <ul className="resumeUL-label">
                      <h1 className="resumeTitle">{resume.title}</h1>

                      {resume.topics.map((topic, j) => (
                        <li key={j} className="resumesTopics">
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </ul>
                </Fade>
              ))}
            </Col>
          </Row>
        </Container>
      </Div>
    );
  }
}

export default About;
