import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Div } from "../elements";
import "./style.scss";
import SocialMedia from "./socialMedia";

class Footer extends React.Component {
  render() {
    const { className, id } = this.props;
    return (
      <Div className={className} id={id}>
        <div className="info">
          <Container>
            <Row>
              <Col xl={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
                <SocialMedia></SocialMedia>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright">
          <Container className="copyrightContainer">
            <h3 class="heading-tertiary">
              Crafted with Passion. Dedication &amp; a lot of coding !
            </h3>
            <p class="u-text-black">© copyright 2020 | All rights reserved.</p>
          </Container>
        </div>
      </Div>
    );
  }
}

export default Footer;
