import {
  faGithub,
  faLinkedin,
  faXing
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./socialMedia.scss";

export default class Gadget extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="icon-wrap flex row">
          <a
            href="https://github.com/jmoeller93"
            target="_blank"
            rel="noopener noreferrer"
            itemProp="sameAs"
          >
            <div className="flex icon" id="icon-2">
              <i className="mdi mdi-linkedin">
                <FontAwesomeIcon
                  className="fontIcon"
                  icon={faGithub}
                  size="lg"
                ></FontAwesomeIcon>
              </i>
            </div>
          </a>

          {/* <a
            href="https://github.com/jmoeller93"
            target="_blank"
            rel="noopener noreferrer"
            itemProp="sameAs"
          >
            <div className="flex icon" id="icon-2">
              <i className="mdi mdi-linkedin">
                <FontAwesomeIcon
                  className="fontIcon"
                  icon={faTwitter}
                  size="lg"
                ></FontAwesomeIcon>
              </i>
            </div>
          </a> */}

          <a
            href="https://www.linkedin.com/in/julian-m%C3%B6ller-58624ab1/"
            target="_blank"
            rel="noopener noreferrer"
            itemProp="sameAs"
          >
            <div className="flex icon" id="icon-2">
              <i className="mdi mdi-linkedin">
                <FontAwesomeIcon
                  className="fontIcon"
                  icon={faLinkedin}
                  size="lg"
                ></FontAwesomeIcon>
              </i>
            </div>
          </a>

          <a
            href="https://www.xing.com/profile/Julian_Moeller10/cv"
            target="_blank"
            rel="noopener noreferrer"
            itemProp="sameAs"
          >
            <div className="flex icon" id="icon-2">
              <i className="mdi mdi-linkedin">
                <FontAwesomeIcon
                  className="fontIcon"
                  icon={faXing}
                  size="lg"
                ></FontAwesomeIcon>
              </i>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
