import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Portfolio from "../portfolio";
// import Maintenance from "../../components/Maintenance";
import NotFoundPage from "../404";

const App = () => {
  return (
    <Fragment>
      <Switch>
        {/* <Route path="/" exact component={Maintenance} />{" "} */}
        <Route path="/" exact component={Portfolio} />{" "}
        <Route component={NotFoundPage} />{" "}
      </Switch>{" "}
    </Fragment>
  );
};

export default App;
