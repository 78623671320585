import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Div } from "../elements";
import SectionTitle from "../SectionTitle";
import "./style.scss";
import Button from "@material-ui/core/Button";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "../../images/portfolio/projects/Navbar.png";
import NavbarPoPUp from "../../images/portfolio/projects/Navbar-PoPUp.png";

import CardHover from "../../images/portfolio/projects/CardHover.png";
import CardHoverPopUp from "../../images/portfolio/projects/CardHoverPopUp.png";

import LoadingAnimation from "../../images/portfolio/projects/LoadingAnimation.png";
import LoadingAnimationPopup from "../../images/portfolio/projects/LoadingAnimationPopup.png";

import BudgedApp from "../../images/portfolio/projects/BudgetApp.png";
import BudgedAppPopup from "../../images/portfolio/projects/BudgetApp.png";

import TMDB from "../../images/portfolio/projects/TMDB.png";

const projects = [
  {
    title: "Budget App",
    image: BudgedApp,
    hoverImage: BudgedAppPopup,
    tags: ["Javascript", "React", "..."],
    gitHub: "https://github.com/jmoeller93/BudgetApp",
    liveDemo: "https://budgetapp.now.sh/"
  },
  // {
  //   title: "Reporting Tool",
  //   image: project1,
  //   hoverImage: project1,
  //   tags: ["Javascript", "React", "..."],
  //   gitHub: "https://codesandbox.io/s/navbar-with-pop-up-nbrk6",
  //   liveDemo: "https://codesandbox.io/s/navbar-with-pop-up-nbrk6"
  // },
  {
    title: "TMDB Movie Search",
    image: TMDB,
    hoverImage: TMDB,
    tags: ["React", "Javascript", "TMDB API"],
    gitHub: "https://github.com/jmoeller93/tmdb-app",
    liveDemo: "https://tmdb-app.julianmoeller93.now.sh/"
  },
  {
    title: "Beautiful Navbar",
    image: Navbar,
    hoverImage: NavbarPoPUp,
    tags: ["Javascript", "SAPUI5", "Fiori"],
    gitHub: "https://github.com/jmoeller93/NavbarPopUp",
    liveDemo: "https://navbarpopup.now.sh/"
  },
  {
    title: "Card Hover",
    image: CardHover,
    hoverImage: CardHoverPopUp,
    tags: ["Javascript", "SAPUI5", "Fiori"],
    gitHub: "https://github.com/jmoeller93/ButtonHoverEffect",
    liveDemo: "https://buttonhovereffect.now.sh/"
  },
  {
    title: "Fun LoadingAnimation",
    image: LoadingAnimation,
    hoverImage: LoadingAnimationPopup,
    tags: ["Javascript", "TMDB API"],
    gitHub: "https://github.com/jmoeller93/LoadingAnimation",
    liveDemo: "https://loadinganimation.now.sh/"
  }
];

class ProjectsComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      active: null,
      modalContent: null
    };
  }

  handleClick(index) {
    this.setState({ active: index });
  }

  render() {
    const { className, id } = this.props;
    return (
      <Div className={className} id={id}>
        <Container className="projectContainer">
          <Row>
            <Col xl={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
              <SectionTitle
                className="sectionTitle"
                title="Free-Time Coding"
              // text="Simply a collection of side projects, which I use to develop myself further.
              // Nothing of this is in use for real projects."
              />
            </Col>
          </Row>
          <Row>
            {projects.map((project, index) => (
              <Col xs="6" sm="4">
                <div class="grid">
                  <figure class="effect-ruby">
                    <img className="myImage" src={project.image} alt="img13" />
                    <figcaption>
                      <h2>{project.title}</h2>
                      <div className="buttonArea">
                        <Button
                          variant="contained"
                          href={project.gitHub}
                          target="_blank"
                          color="default"
                          className="gitHubButton"
                          startIcon={
                            <FontAwesomeIcon
                              className="fontIcon"
                              icon={faGithub}
                              size="lg"
                            ></FontAwesomeIcon>
                          }
                        >
                          Repository
                        </Button>

                        <Button
                          variant="contained"
                          href={project.liveDemo}
                          target="_blank"
                          color="default"
                          className="demoButton"
                          startIcon={
                            <FontAwesomeIcon
                              className="fontIcon"
                              icon={faGithub}
                              size="lg"
                            ></FontAwesomeIcon>
                          }
                        >
                          Demo
                        </Button>
                      </div>

                      {/* <p>Ruby did not need any help. Everybody knew that.</p>
                      <a href="#">View more</a> */}
                    </figcaption>
                  </figure>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Div>
    );
  }
}

export default ProjectsComponent;
