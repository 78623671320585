import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Div } from "../elements";
import SectionTitle from "../SectionTitle";
import "./style.scss";
import Fade from "react-reveal/Fade";

import TMDB from "../../images/portfolio/projects/TMDB.png";
import portfolio from "../../images/portfolio/projects/Portfolio.png";

import {
  faLaptop,
  faUserFriends,
  faBlog,
  faBlender
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import project1 from "../../images/travel/offer/AR-Report.PNG";

const laptop = <FontAwesomeIcon icon={faLaptop} />;
const consultant = <FontAwesomeIcon icon={faUserFriends} />;
const blog = <FontAwesomeIcon icon={faBlog} />;
const blender = <FontAwesomeIcon icon={faBlender} />;

class ResumeComponent extends React.Component {
  render() {
    const { className, id } = this.props;
    return (
      <Div className={className} id={id}>
        <Container className="resumeContainer">
          <Row>
            <Col xl={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
              <SectionTitle
                className="sectionTitle"
                title="Skill & Expertise"
              />
            </Col>
          </Row>
          <Row>
            <Col className="" md={7}>
              <Fade left>
                <Row>
                  <Col className="" md={6}>
                    <Row>
                      <Col md={3}>
                        <p>{laptop}</p>
                      </Col>
                      <Col md={9}>
                        <h3 class="heading-tertiary">Web Development</h3>
                        <p>
                          I like to code things from scratch, and enjoy bringing
                          ideas to life in the browser.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="" md={6}>
                    <Row>
                      <Col md={3}>
                        <p>{blog}</p>
                      </Col>
                      <Col md={9}>
                        <h3 class="heading-tertiary">UI/UX DESIGN</h3>
                        <p>
                          I tend to code things from scratch, and enjoy bringing
                          ideas to life in the browser.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="" md={6}>
                    <Row>
                      <Col md={3}>
                        <p>{consultant}</p>
                      </Col>
                      <Col md={9}>
                        <h3 class="heading-tertiary">Consultant</h3>
                        <p>
                          I work myself into everything to find a suitable
                          solution for your problem.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="" md={6}>
                    <Row>
                      <Col md={3}>
                        <p>{blender}</p>
                      </Col>
                      <Col md={9}>
                        <h3 class="heading-tertiary">Backend Development</h3>
                        <p>Currently working with Java and ABAP.</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="" md={6}></Col>
                </Row>
              </Fade>
            </Col>
            <Col className="" md={5}>
              <Fade right>
                <div className="composition" data-v-445f4844>
                  <img
                    className="composition__img composition__img--p1"
                    src={TMDB}
                    data-v-445f4844
                  ></img>
                  <img
                    className="composition__img composition__img--p2"
                    src={project1}
                    data-v-445f4844
                  ></img>
                  <img
                    className="composition__img composition__img--p3"
                    src={portfolio}
                    data-v-445f4844
                  ></img>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Div>
    );
  }
}

export default ResumeComponent;
