import React, { Fragment } from "react";
import LandingPage from "../../components/LandingPage";
import Skills from "../../components/Skills";
import About from "../../components/About/index";
import Projects from "../../components/Projects";
import Resume from "../../components/Resume";
import Footer from "../../components/Footer/index";

const Portfolio = () => {
  return (
    <Fragment>
      <LandingPage className="travelHeroArea" id="home" />
      <Resume className="resumeArea" id="resume" />
      <Skills className="skillsArea" id="skills" />

      <Projects className="projectsArea" id="projects" />
      <About className="aboutArea" id="about" />

      {/* <Contact className="ContactArea" id="contact" travel={true} /> */}
      <Footer className="footerArea" id="footer" />
    </Fragment>
  );
};
export default Portfolio;
